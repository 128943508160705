import React from 'react'
import { Box, Text, Card, Flex } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

const PageAbout = props => {
  return (
    <Layout {...props}>
      <Seo title='プロフィール' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='プロフィール'
            subheader=''
          />
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='自己紹介'>
                <Text variant='p'>
                  はじめまして<br />
                  当ブログを閲覧いただきありがとうございます。
                </Text>
                <Text variant='p'>
                  このページでは、かんたんな自己紹介をさせていただきます。
                </Text>
                <Text variant='p'>
                  私の出身は愛媛県松山市で、育ちも同様です。<br />
                  現在は、市内の某所でエンジニアをしています。
                </Text>
                <Text variant='p'>
                  業界経験は約8年で、おもにWebプロダクトの開発に従事しています。<br />
                  その他、スマホアプリの開発にも従事したことがあります。
                </Text>
                <Text variant='p'>
                  ほぼ全ての工程を一通り経験してきましたが、自身は上流工程よりも下流工程に適性があると感じています。
                </Text>
                <Text variant='p'>
                  好きな言語は JavaScript で、1つの言語で何でもできるところが好きです。<br />
                  バックエンドは PHP の経験が長いですが、最近は Python や Node.js にふれる機会が増えています。
                </Text>
                <Text variant='p'>
                  プライベートでは時たまロードバイクに乗っています。<br />
                  適度な運動になるところ、自分次第でどこへでも行けちゃうところが好きです。
                </Text>
                <Text variant='p'>
                  当ブログでは自身が経験した物事について書いていきます。<br />
                  まったり更新して、細く長く続けたいと思っています。<br />
                  記事が少しでも誰かのお役に立てたなら、たいへん嬉しく思います。
                </Text>
                <Text variant='p'>
                  以上になります。<br />
                  ここまで読んでいただきありがとうございました。
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='バックエンド'>
                <Card variant='paper'>
                  <ul>
                    <li>PHP
                      <ul>
                        <li>Laravel</li>
                        <li>WordPress</li>
                        <li>CodeIgniter</li>
                      </ul>
                    </li>
                    <li>Python
                      <ul>
                        <li>FastAPI</li>
                      </ul>
                    </li>
                    <li>Node.js (JavaScript / TypeScript)
                      <ul>
                        <li>NestJS</li>
                        <li>Express</li>
                      </ul>
                    </li>
                    <li>Kotlin</li>
                  </ul>
                </Card>
              </Section>
              <Divider />
              <Section title='フロントエンド'>
                <Card variant='paper'>
                  <ul>
                    <li>HTML / CSS
                      <ul>
                        <li>Bootstrap</li>
                        <li>Tailwind CSS</li>
                      </ul>
                    </li>
                    <li>SCSS / Sass</li>
                    <li>JavaScript / TypeScript
                      <ul>
                        <li>React</li>
                        <li>Vue.js</li>
                        <li>Svelte</li>
                      </ul>
                    </li>
                  </ul>
                </Card>
              </Section>
              <Divider />
              <Section title='インフラ'>
                <Card variant='paper'>
                  <ul>
                    <li>Amazon Web Services (AWS)</li>
                    <li>Docker</li>
                    <li>Terraform</li>
                    <li>DevOps</li>
                  </ul>
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageAbout
